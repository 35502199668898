import React, { useEffect, useState } from "react";
import img from "../../assets/img/outcess-logo-white.png";
import TableLoader from "../../components/TableLoader";
import toast, { Toaster } from "react-hot-toast";
const HireFormHeader = ({ isLoadingID }) => {
  const [network, setnetwork] = useState();

  window.addEventListener("offline", (e) => setnetwork("offline"));
  window.addEventListener("online", (e) => setnetwork("online"));
  useEffect(() => {
    if (network === "online") {
      toast.success("You are back online!");
    } else if (network === "offline") {
      toast.error("You have lost internet connection!");
    }
  }, [network]);

  return (
    <div>
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 5000,
          error: {
            duration: 7000,
          },
        }}
      />
      <div className="Hire-first-header img-width">
        <img src={img} alt="logo" className="Hire-first-header-logo" />
      </div>
      <div className="Hire-first-second"></div>
      <TableLoader isLoading={isLoadingID} />
    </div>
  );
};

export default HireFormHeader;
