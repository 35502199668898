/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { FiMail } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import moment from "moment";
import { Formik } from "formik";
import * as yup from "yup";
import Skelenton from "../../components/Skelenton";
import ExpiredPage from "../ExpiredPage/ExpiredPage";
import swal from "sweetalert";
import { baseUrl } from "../../shared/baseUrl";
import axios from "axios";
import HireFormHeader from "./HireFormHeader";

const Hire = ({ history, match }) => {
  const id = match.params.id;
  const [dataID, setJob] = useState([]);
  const [isLoadingID, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [messageID, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/v1/job/${id}`);
        if (!response.ok) {
          swal({
            title: "error",
            text: "Network response was not ok",
            icon: "error",
          });
        }
        const data = await response.json();
        setJob(data);
      } catch (error) {
        setError(true);
        setErrorMessage(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchJob();
  }, [id]);

  const handleform = async (values) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/v1/application/check-existing/${id}`,
        {
          email: values?.email,
        }
      );
      if (response?.data?.applicationExists === true) {
        swal({
          title: "Error",
          text: response?.data?.message,
          icon: "error",
        });
      } else if (response?.data?.applicationExists === false && values?.email) {
        history.push("/hireform", {
          state: { email: values?.email, data: dataID, id: id },
        });
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error.message);
    }
  };

  const loginValidationSchema = (dataID) => {
    if (dataID?.data?.makeJobPrivate === true) {
      return yup.object().shape({
        email: yup
          .string()
          .email("Please enter your Outcess internal email")
          .required("Email Address is Required"),
      });
    } else {
      return yup.object().shape({
        email: yup
          .string()
          .email("Please enter valid email")
          .required("Email Address is Required"),
      });
    }
  };

  useEffect(() => {
    if (error) {
      swal({
        title: "error",
        text: messageID,
        icon: "error",
      });
    }
  }, [error, messageID]);

  useEffect(() => {
    const date = moment();
    const expired = moment(dataID?.data?.expiryDate);
    if (expired.isAfter(date)) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [dataID]);

  return (
    <div id="header" className="HireForm-container">
      <HireFormHeader isLoadingID={isLoadingID} />
      <main>
        <div className="Hire-email-apply">
          <div className="job-title  ">JOB CODE - {dataID?.data?.jobCode}</div>
          <div className="job-title-locatiom">
            <IoLocationOutline size={25} />
            <span className=" break-word-pre-line ">
              {dataID?.data?.jobLocation}
            </span>
          </div>
          <Formik
            validationSchema={loginValidationSchema(dataID)}
            initialValues={{ email: "" }}
            onSubmit={handleform}>
            {({ handleChange, handleSubmit, errors, values, touched }) => (
              <div className="email-apply-container">
                <form>
                  <div className="email-apply-container-flex">
                    <span className="input-icon-wrapper">
                      <div className="inputWithIcon inputIconBg">
                        <input
                          type="email"
                          placeholder="Email"
                          value={values.email}
                          onChange={handleChange("email")}
                        />
                        <i className="i-icon">
                          {" "}
                          <FiMail size={25} />
                        </i>
                      </div>
                    </span>
                    <span>
                      {/* @ts-ignore */}
                      <Button
                        type="submit"
                        className="summary-bottom-btn"
                        onClick={handleSubmit}>
                        Apply
                      </Button>
                    </span>
                  </div>
                  {errors.email && (
                    <p className="formik-errors">{errors.email}</p>
                  )}
                </form>
              </div>
            )}
          </Formik>
        </div>

        {/* Total Work Experience   */}
        {isLoadingID ? (
          <Skelenton />
        ) : (
          <div className="Hire-email-apply">
            <div>
              <div className="summary-container-title-sub summary-job-details">
                JOB DETAILS
              </div>
            </div>
            <div className="summary-main">
              <div id="Job__sections">
                <div className="details">
                  <div className="details__content">
                    <div className="details__content__left">
                      <label>
                        <p>Job Title</p>
                      </label>
                      {isLoadingID ? (
                        <p className="card-text placeholder-glow">
                          <span className="placeholder col-10 subheading"></span>
                        </p>
                      ) : (
                        <p className="subheading">{dataID?.data?.jobProfile}</p>
                      )}
                      <label>
                        <p>Experience Level</p>
                      </label>

                      {isLoadingID ? (
                        <p className="card-text placeholder-glow">
                          <span className="placeholder col-7 subheading"></span>
                        </p>
                      ) : (
                        <p className="subheading">
                          {!dataID?.data?.experienceLevel
                            ? "N/A"
                            : dataID?.data?.experienceLevel}
                        </p>
                      )}
                      <label>
                        <p>Remote</p>
                      </label>
                      <p className="subheading">
                        {dataID?.data?.remote === false ? "No" : "Yes"}
                      </p>
                    </div>
                    <div className="details__content__right">
                      <label>
                        <p>Job Function</p>
                      </label>
                      {isLoadingID ? (
                        <p className="card-text placeholder-glow">
                          <span className="placeholder col-7 subheading"></span>
                        </p>
                      ) : (
                        <p className="subheading">
                          {dataID?.data?.jobFunction}
                        </p>
                      )}
                      <label>
                        <p>Employment Type</p>
                      </label>
                      {isLoadingID ? (
                        <p className="card-text placeholder-glow">
                          <span className="placeholder col-7 subheading"></span>
                        </p>
                      ) : (
                        <p className="subheading">
                          {!dataID?.data?.employmentType
                            ? "Optional"
                            : dataID?.data?.employmentType}
                        </p>
                      )}
                      <label>
                        <p>Location</p>
                      </label>
                      {isLoadingID ? (
                        <p className="card-text placeholder-glow">
                          <span className="placeholder col-7 subheading"></span>
                        </p>
                      ) : (
                        <p className="subheading">
                          {!dataID?.data?.jobLocation
                            ? "N/A"
                            : dataID?.data?.jobLocation}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="details">
                  <div className="details__content">
                    <div className="details__content__left">
                      {/* <label><p>Job Profile</p></label>
										{isLoadingID ? <p className="card-text placeholder-glow">
											<span className="placeholder col-10 subheading"></span>
										</p> : <p className='subheading'>{!dataID?.data?.jobProfile ? "N/A" : dataID?.data?.jobProfile}</p>} */}

                      <label>
                        <p>Qualifications</p>
                      </label>
                      {isLoadingID ? (
                        <p className="card-text placeholder-glow">
                          <span className="placeholder col-10 subheading"></span>
                        </p>
                      ) : (
                        <p className="subheading">
                          {!dataID?.data?.qualifications
                            ? "N/A"
                            : dataID?.data?.qualifications}
                        </p>
                      )}
                      <label>
                        <p>Education</p>
                      </label>
                      {isLoadingID ? (
                        <p className="card-text placeholder-glow">
                          <span className="placeholder col-7 subheading"></span>
                        </p>
                      ) : (
                        <p className="subheading">
                          {!dataID?.data?.education
                            ? "N/A"
                            : dataID?.data?.education}
                        </p>
                      )}
                    </div>

                    <div className="details__content__right">
                      <label>
                        <p>Number of Openings</p>
                      </label>
                      {isLoadingID ? (
                        <p className="card-text placeholder-glow">
                          <span className="placeholder col-10 subheading"></span>
                        </p>
                      ) : (
                        <p className="subheading">
                          {!dataID?.data?.numberOfOpenings
                            ? "N/A"
                            : dataID?.data?.numberOfOpenings}
                        </p>
                      )}
                      <label>
                        <p>Expiry Date</p>
                      </label>
                      {isLoadingID ? (
                        <p className="card-text placeholder-glow">
                          <span className="placeholder col-10 subheading"></span>
                        </p>
                      ) : (
                        <p className="subheading">
                          {!dataID?.data?.expiryDate
                            ? "N/A"
                            : moment(dataID?.data?.expiryDate).format(
                                "DD-MM-YYYY"
                              )}
                        </p>
                      )}

                      <label>
                        <p>Team</p>
                      </label>
                      {isLoadingID ? (
                        <p className="card-text placeholder-glow">
                          <span className="placeholder col-10 subheading"></span>
                        </p>
                      ) : (
                        <p className="subheading">{dataID?.data?.team}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="summary-container-hire">
                <div>
                  <h5>Job Summary</h5>
                  <p>{dataID?.data?.summary}</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div></div>
      </main>
      {show ? <ExpiredPage setShow={setShow} show={show} /> : ""}
    </div>
  );
};

export default Hire;
