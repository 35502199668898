import axios from "axios";
import { useEffect, useState } from "react";
import { baseUrl } from "../../shared/baseUrl";
import swal from "sweetalert";
import { ProgressBar } from "react-bootstrap";

const UploadID = ({ setInputs }) => {
  const [image, setImage] = useState("");
  const [fileName, setFileName] = useState("");
  const [progress, setProgress] = useState(0);

  const handleChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      // setFile(selectedFile);
      setFileName(selectedFile.name);
      setProgress(0); // Reset progress when a new file is selected
      handleSubmit(selectedFile);
    } else {
      // setFile(null);
      setFileName("");
      swal("Invalid file type", "Please select a PDF file", "error");
    }
  };

  const handleSubmit = (selectedFile) => {
    if (!selectedFile) {
      swal("No file selected", "Please select a file to upload", "error");
      return;
    }

    const url = `${baseUrl}/api/v1/application/uploadverificationID`;
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("fileName", selectedFile.name);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        if (response.data.message === "success") {
          setImage(response.data.data);
          swal("Resume Uploaded Successfully", "", "success");
        } else {
          swal("Resume Upload Failed", "", "error");
        }
      })
      .catch((error) => {
        console.error(error);
        swal("An error occurred", "Please try again later", "error");
      });
  };

  useEffect(() => {
    setInputs((prevState) => {
      return {
        ...prevState,
        verificationID: image,
      };
    });
  }, [image, setInputs]);

  return (
    <div className="page">
      <div className="fileupload-view">
        <div className="row justify-content-center m-0">
          <div className="col-md-6-main">
            <div className="card">
              <div className="card-body">
                <div className="kb-data-box">
                  <div className="kb-modal-data-title">
                    <div className="kb-data-title">
                      <h6>Upload Identification With Date of Birth</h6>
                    </div>
                  </div>
                  <form>
                    <div className="kb-file-upload">
                      <div className="file-upload-box">
                        <input
                          type="file"
                          id="fileupload"
                          accept="application/pdf"
                          className="file-upload-input"
                          onChange={handleChange}
                        />
                        <span>
                          Drag and drop or{" "}
                          <span className="file-link">Choose your files</span>
                        </span>
                      </div>
                      <ProgressBar
                        className="upload-progress-bar"
                        now={progress}
                      />
                    </div>
                    <div className="kb-attach-box">
                      {fileName && <p>Selected file: {fileName}</p>}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadID;
