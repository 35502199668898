import React from 'react'

const Skelenton = () => {
	return (
		<div style={{ width: "100%" }}>
			<div className="loading-simulator">
				<div className="loading-simulator-header"></div>
				<div className="loading-simulator-content">
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</div>
	)
}

export default Skelenton
