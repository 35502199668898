
import React from 'react'
import { Modal } from 'react-bootstrap'


const ExpiredPage = ({ show, setShow }) => {



	return (
		<div>
			<Modal fullscreen show={show} onHide={() => setShow(false)}>
				<Modal.Body>
					<div className='expired-container'>
						<div className='expired-right'>
							<div className="message">
								<h1>Oops, this link is expired</h1>
								<p>This JOB is not valid anymore.</p>
							</div>
						</div>
						<div className='expired-left'>

						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default ExpiredPage
