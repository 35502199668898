import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DragDropFile from "./DragDropFile";
import { Gender, Salutation } from "../../components/JobInputData";
import swal from "sweetalert";
import { useLocation } from "react-router-dom";
import HireFormHeader from "./HireFormHeader";
import { Spinners } from "../../components/TableOptions";
import { BsDot } from "react-icons/bs";
import moment from "moment";
import { IoMdAddCircle } from "react-icons/io";
import { AiFillMinusCircle } from "react-icons/ai";
import UploadID from "./UploadID";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { baseUrl } from "../../shared/baseUrl";

function getSteps() {
  return [
    <b>Upload Your CV</b>,
    <b>Upload Identification With Date of Birth</b>,
  ];
}

function getStepContent(step, setInputs, setActiveStep) {
  switch (step) {
    case 0:
      return (
        <DragDropFile setInputs={setInputs} setActiveStep={setActiveStep} />
      );
    case 1:
      return <UploadID setInputs={setInputs} />;
    default:
      return "Unknown step";
  }
}
const HireForm = ({ history, match }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [age, setAge] = useState();
  const [isAgeCorrect, setIsAgeCorrect] = useState(null);
  const [isErrorapp, setisErrorapp] = useState(false);
  const [isSuccessapp, setisSuccessapp] = useState(false);
  const [messageapp, setmessageapp] = useState("");
  const [isLoadingapp, setisLoadingapp] = useState(false);
  const steps = getSteps();
  const location = useLocation();

  const [date1, setDate1] = useState([
    {
      id: 1,
      school: "",
      course: "",
      startDate: "",
      endDate: "",
    },
  ]);
  const [date2, setDate2] = useState([
    {
      id: 1,
      company: "",
      role: "",
      startDate: "",
      endDate: "",
    },
  ]);

  // --- Adds New Field --- //
  const handleAddField1 = () => {
    setDate1([
      ...date1,
      {
        id: date1.length + 1,
        school: "",
        course: "",
        startDate: "",
        endDate: "",
      },
    ]);
  };

  // --- Adds New Field --- //
  const handleAddField2 = () => {
    setDate2([
      ...date2,
      {
        id: date2.length + 1,
        company: "",
        role: "",
        startDate: "",
        endDate: "",
      },
    ]);
  };

  const [inputs, setInputs] = useState({
    title: "",
    firstName: "",
    middleName: "",
    LastName: "",
    // @ts-ignore
    email: location?.state?.state?.email,
    linkedInUrl: "",
    phoneNumber: "",
    location: "",
    currency: "NGN",
    currentSalary: "₦",
    expectedSalary: "₦",
    relevantWorkExperience: "",
    noticePeriod: "",
    reasonForChange: "",
    additionalDetails: "",
    totalWorkExperience: "",
    resume: "",
    verificationID: "",
    // @ts-ignore
    jobId: location?.state?.state?.id,
    Education: [],
    WorkExperience: [],
    gender: "",
    dateOfBirth: "",
  });

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  useEffect(() => {
    const calculatedAge = calculateAge(inputs.dateOfBirth);
    setIsAgeCorrect(calculatedAge === parseInt(age, 10));
  }, [age, inputs.dateOfBirth]);

  const date1a = date1?.map((item) => ({
    school: item.school,
    course: item.course,
    startDate: item.startDate,
    endDate: item.endDate,
  }));
  const date2a = date2?.map((item) => ({
    company: item.company,
    role: item.role,
    startDate: item.startDate,
    endDate: item.endDate,
  }));

  useEffect(() => {
    inputs.Education = date1a;
    inputs.WorkExperience = date2a;
  }, [date1a, date2a, inputs]);

  const handleData1 = (input, value, index) => {
    let items = [...date1];
    let oldItem = items.findIndex((x) => x.id === index);
    let newItem = {
      ...items[oldItem],
      [input]:
        input === "startDate" || input === "endDate"
          ? moment(new Date(value)).format()
          : value,
    };
    items[oldItem] = newItem;
    setDate1(items);
  };

  const handleData2 = (input, value, index) => {
    let items = [...date2];
    let oldItem = items.findIndex((x) => x.id === index);
    let newItem = {
      ...items[oldItem],
      [input]:
        input === "startDate" || input === "endDate"
          ? moment(new Date(value)).format()
          : value,
    };
    items[oldItem] = newItem;
    setDate2(items);
  };

  // --- Remove New Field   --- //
  const handleRemoveField1 = (index) => {
    const field = [...date1];
    field.splice(index, 1);
    setDate1(field);
  };
  // --- Remove New Field   --- //
  const handleRemoveField2 = (index) => {
    const field = [...date2];
    field.splice(index, 1);
    setDate2(field);
  };

  useEffect(() => {
    if (isErrorapp) {
      swal({
        title: "error",
        text: messageapp,
        icon: "error",
      });
    } else if (isSuccessapp) {
      swal({
        title: "success",
        text: "Your Application Is Sucessful!",
        icon: "success",
      }).then((okay) => {
        if (okay) {
          history.push("current-openings");
        }
      });
    }
  }, [isErrorapp, isSuccessapp, messageapp, history]);

  const handleOnChange = (input, value) => {
    setInputs((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  const handlePost = async (e) => {
    e.preventDefault();

    // Validate age with date of birth
    if (!isAgeCorrect) {
      swal({
        title: "Error",
        text: "Your age does not match your date of birth date",
        icon: "error",
      });
      return;
    }

    try {
      setisLoadingapp(true);
      setisErrorapp(false);
      setisSuccessapp(false);
      setmessageapp("");

      // Make the POST request
      const { data } = await axios.post(
        `${baseUrl}/api/v1/application`,
        inputs
      );
      console.log(data);
      // Update state on success
      setisLoadingapp(false);
      setisSuccessapp(true);
      setmessageapp("Application submitted successfully!");

      swal({
        title: "Success",
        text: "Application submitted successfully!",
        icon: "success",
      });
    } catch (error) {
      setisLoadingapp(false);
      setisErrorapp(true);

      // Extract and display the error message
      const errorMessage =
        error.response && error.response.data
          ? error.response.data.errors[0].message
          : error.message || "An error occurred. Please try again.";
      setmessageapp(errorMessage);

      swal({
        title: "Error",
        text: errorMessage,
        icon: "error",
      });
    }
  };

  return (
    <div id="header" className="HireForm-container">
      <HireFormHeader isLoadingID={isLoadingapp} />
      <main>
        <div className="addsteppercontainer_sup mb-4">
          <div className="addsteppercontainer">
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    <Typography>
                      {getStepContent(index, setInputs, setActiveStep)}
                    </Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
        <form onSubmit={handlePost}>
          <div>
            <div className="Hire-first-second-main-container Hire-main-apply">
              <div id="Profile__Information">
                <div className="applicant__Title">
                  <p>Profile Information</p>
                </div>
                <div className="Information__basic">
                  <div className="Basic__Info">
                    <div className="New__applicant__row">
                      <p>Title</p>
                      <select
                        className="round"
                        value={inputs?.title}
                        required={true}
                        onChange={(e) =>
                          handleOnChange("title", e.target.value)
                        }>
                        <option>Salutation</option>
                        {Salutation?.map((item, i) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="New__applicant__row">
                      <p>
                        First Name{" "}
                        {!location?.state?.state?.data?.data?.firstname ? (
                          <span className="hire-optional">
                            <i> Optional</i>
                          </span>
                        ) : (
                          <span>
                            <BsDot color="red" size={25} />{" "}
                            <i className="hire-impotant">Important</i>
                          </span>
                        )}
                      </p>
                      <input
                        type="text"
                        className="AddJobinput"
                        value={inputs.firstName}
                        onChange={(e) =>
                          handleOnChange("firstName", e.target.value)
                        }
                        placeholder="First Name"
                      />
                    </div>
                  </div>
                  <div className="Basic__Info">
                    <div className="New__applicant__row">
                      <p>
                        Middle Name{" "}
                        {!location?.state?.state?.data?.data?.middlename ? (
                          <span className="hire-optional">
                            <i> Optional</i>
                          </span>
                        ) : (
                          <span>
                            <BsDot color="red" size={25} />{" "}
                            <i className="hire-impotant">Important</i>
                          </span>
                        )}
                      </p>
                      <input
                        type="text"
                        className="AddJobinput"
                        value={inputs?.middleName}
                        onChange={(e) =>
                          handleOnChange("middleName", e.target.value)
                        }
                        placeholder="Middle Name"
                      />
                    </div>

                    <div className="New__applicant__row">
                      <p>
                        Last Name{" "}
                        {!location?.state?.state?.data?.data?.lastname ? (
                          <span className="hire-optional">
                            <i> Optional</i>
                          </span>
                        ) : (
                          <span>
                            <BsDot color="red" size={25} />{" "}
                            <i className="hire-impotant">Important</i>
                          </span>
                        )}
                      </p>
                      <input
                        type="text"
                        className="AddJobinput"
                        value={inputs?.LastName}
                        onChange={(e) =>
                          handleOnChange("LastName", e.target.value)
                        }
                        placeholder="Last Name"
                      />
                    </div>
                  </div>

                  <div className="Basic__Info">
                    <div className="New__applicant__row">
                      <p>Gender</p>
                      <select
                        className="round"
                        value={inputs?.gender}
                        onChange={(e) =>
                          handleOnChange("gender", e.target.value)
                        }
                        required>
                        <option>Gender</option>
                        {Gender?.map((item, i) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="New__applicant__row">
                      <p>Age </p>
                      <input
                        type="number"
                        className="AddJobinput"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                        placeholder="Age"
                        required
                      />
                    </div>
                  </div>
                  <div className="Basic__Info">
                    <div className="New__applicant__row">
                      <p>Date Of Birth </p>
                      <input
                        type="date"
                        className="AddJobinput"
                        value={moment(new Date(inputs?.dateOfBirth)).format(
                          "YYYY-MM-DD"
                        )}
                        onChange={(e) =>
                          handleOnChange("dateOfBirth", e.target.value)
                        }
                        placeholder="Date Of Birth"
                        required
                      />
                    </div>

                    <div className="notice__period">
                      <p>
                        {" "}
                        Email{" "}
                        {!location?.state?.state?.data?.data?.email ? (
                          <span className="hire-optional">
                            <i> Optional</i>
                          </span>
                        ) : (
                          <span>
                            <BsDot color="red" size={25} />{" "}
                            <i className="hire-impotant">Important</i>
                          </span>
                        )}{" "}
                      </p>
                      <input
                        type="text"
                        className="AddJobinput"
                        value={inputs?.email}
                        onChange={(e) =>
                          handleOnChange("email", e.target.value)
                        }
                        placeholder="Email"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="Basic__Info">
                    {location?.state?.state?.data?.data?.linkedinUrl && (
                      <div className="notice__period">
                        <p>
                          LinkedIn Url{" "}
                          {!location?.state?.state?.data?.data?.linkedinUrl ? (
                            <span className="hire-optional">
                              <i> Optional</i>
                            </span>
                          ) : (
                            <span>
                              <BsDot color="red" size={25} />{" "}
                              <i className="hire-impotant">Important</i>
                            </span>
                          )}
                        </p>

                        <input
                          type="text"
                          className="AddJobinput"
                          value={inputs?.linkedInUrl}
                          onChange={(e) =>
                            handleOnChange("linkedInUrl", e.target.value)
                          }
                          placeholder="LinkedIn Url"
                        />
                      </div>
                    )}
                    {location?.state?.state?.data?.data?.Phone && (
                      <div className="New__applicant__row">
                        <p>
                          Phone{" "}
                          {!location?.state?.state?.data?.data?.Phone ? (
                            <span className="hire-optional">
                              <i> Optional</i>
                            </span>
                          ) : (
                            <span>
                              <BsDot color="red" size={25} />{" "}
                              <i className="hire-impotant">Important</i>
                            </span>
                          )}
                        </p>

                        <input
                          type="text"
                          className="AddJobinput"
                          value={inputs?.phoneNumber}
                          onChange={(e) =>
                            handleOnChange("phoneNumber", e.target.value)
                          }
                          placeholder="Phone Number"
                          // @ts-ignore
                          required={location?.state?.state?.data?.data?.Phone}
                        />
                      </div>
                    )}
                  </div>

                  <div className="Basic__Info">
                    {location?.state?.state?.data?.data?.location && (
                      <div className="New__applicant__row">
                        <p>
                          Location{" "}
                          {!location?.state?.state?.data?.data?.location ? (
                            <span className="hire-optional">
                              <i> Optional</i>
                            </span>
                          ) : (
                            <span>
                              <BsDot color="red" size={25} />{" "}
                              <i className="hire-impotant">Important</i>
                            </span>
                          )}
                        </p>

                        <input
                          type="text"
                          className="AddJobinput"
                          value={inputs?.location}
                          onChange={(e) =>
                            handleOnChange("location", e.target.value)
                          }
                          placeholder="Location"
                          required={
                            location?.state?.state?.data?.data?.location
                          }
                        />
                      </div>
                    )}
                    {location?.state?.state?.data?.data?.currency && (
                      <div className="New__applicant__row">
                        <p>
                          {" "}
                          Currency{" "}
                          {!location?.state?.state?.data?.data?.currency ? (
                            <span className="hire-optional">
                              <i> Optional</i>
                            </span>
                          ) : (
                            <span>
                              <BsDot color="red" size={25} />{" "}
                              <i className="hire-impotant">Important</i>
                            </span>
                          )}
                        </p>

                        <input
                          type="text"
                          className="AddJobinput"
                          value={inputs?.currency}
                          onChange={(e) =>
                            handleOnChange("currency", e.target.value)
                          }
                          placeholder="Currency"
                          disabled
                        />
                      </div>
                    )}
                  </div>

                  <div className="Basic__Info">
                    {location?.state?.state?.data?.data?.currentSalary && (
                      <div className="New__applicant__row">
                        <p>
                          Current Salary{" "}
                          {!location?.state?.state?.data?.data
                            ?.currentSalary ? (
                            <span className="hire-optional">
                              <i> Optional</i>
                            </span>
                          ) : (
                            <span>
                              <BsDot color="red" size={25} />{" "}
                              <i className="hire-impotant">Important</i>
                            </span>
                          )}
                        </p>
                        <input
                          type="text"
                          className="AddJobinput"
                          value={inputs.currentSalary}
                          onChange={(e) =>
                            handleOnChange("currentSalary", e.target.value)
                          }
                          placeholder="Currency"
                          required={
                            location?.state?.state?.data?.data?.currentSalary
                          }
                        />
                      </div>
                    )}

                    {location?.state?.state?.data?.data?.expectedSalary && (
                      <div className="New__applicant__row">
                        <p>
                          Expected Salary{" "}
                          {!location?.state?.state?.data?.data
                            ?.expectedSalary ? (
                            <span className="hire-optional">
                              <i> Optional</i>
                            </span>
                          ) : (
                            <span>
                              <BsDot color="red" size={25} />{" "}
                              <i className="hire-impotant">Important</i>
                            </span>
                          )}
                        </p>
                        <input
                          type="text"
                          className="AddJobinput"
                          value={inputs?.expectedSalary}
                          onChange={(e) =>
                            handleOnChange("expectedSalary", e.target.value)
                          }
                          placeholder="Expected Salary"
                          required={
                            location?.state?.state?.data?.data?.expectedSalary
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="Basic__Info">
                    {location?.state?.state?.data?.data?.noticePeriod && (
                      <div className="notice__period">
                        <p>
                          Notice Period (In Days){" "}
                          {!location?.state?.state?.data?.data?.noticePeriod ? (
                            <span className="hire-optional">
                              <i> Optional</i>
                            </span>
                          ) : (
                            <span>
                              <BsDot color="red" size={25} />{" "}
                              <i className="hire-impotant">Important</i>
                            </span>
                          )}
                        </p>
                        <input
                          type="text"
                          className="AddJobinput"
                          value={inputs?.noticePeriod}
                          onChange={(e) =>
                            handleOnChange("noticePeriod", e.target.value)
                          }
                          placeholder="Notice Period"
                          required={
                            location?.state?.state?.data?.data?.noticePeriod
                          }
                        />
                      </div>
                    )}
                    {location?.state?.state?.data?.data?.reasonForJobChange && (
                      <div className="notice__period">
                        <p>
                          Reason for Job Change{" "}
                          {!location?.state?.state?.data?.data
                            ?.reasonForJobChange ? (
                            <span className="hire-optional">
                              <i> Optional</i>
                            </span>
                          ) : (
                            <span>
                              <BsDot color="red" size={25} />{" "}
                              <i className="hire-impotant">Important</i>
                            </span>
                          )}
                        </p>
                        <input
                          type="text"
                          className="AddJobinput"
                          value={inputs?.reasonForChange}
                          onChange={(e) =>
                            handleOnChange("reasonForChange", e.target.value)
                          }
                          placeholder="Reason for Job Change"
                          required={
                            location?.state?.state?.data?.data
                              ?.reasonForJobChange
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className="Basic__Info">
                    {location?.state?.state?.data?.data?.additionalDetails && (
                      <div className="notice__period">
                        <p>
                          Additional Details{" "}
                          {!location?.state?.state?.data?.data
                            ?.additionalDetails ? (
                            <span className="hire-optional">
                              <i> Optional</i>
                            </span>
                          ) : (
                            <span>
                              <BsDot color="red" size={25} />{" "}
                              <i className="hire-impotant">Important</i>
                            </span>
                          )}
                        </p>
                        <input
                          type="text"
                          className="AddJobinput"
                          value={inputs?.additionalDetails}
                          onChange={(e) =>
                            handleOnChange("additionalDetails", e.target.value)
                          }
                          placeholder="Additional Details"
                          required={
                            location?.state?.state?.data?.data
                              ?.additionalDetails
                          }
                        />
                      </div>
                    )}
                    {location?.state?.state?.data?.data
                      ?.totalWorkExperience && (
                      <div className="notice__period">
                        <p>
                          Total Work Experience{" "}
                          {!location?.state?.state?.data?.data
                            ?.totalWorkExperience ? (
                            <span className="hire-optional">
                              <i> Optional</i>
                            </span>
                          ) : (
                            <span>
                              <BsDot color="red" size={25} />{" "}
                              <i className="hire-impotant">Important</i>
                            </span>
                          )}
                        </p>
                        <input
                          type="number"
                          className="AddJobinput"
                          value={inputs?.totalWorkExperience}
                          onChange={(e) =>
                            handleOnChange(
                              "totalWorkExperience",
                              e.target.value
                            )
                          }
                          placeholder="Total Work Experience"
                          required={
                            location?.state?.state?.data?.data
                              ?.totalWorkExperience
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className="Basic__Info">
                    {location?.state?.state?.data?.data
                      ?.relevantWorkExperience && (
                      <div className="notice__period">
                        <p>
                          Relevant Work Experience
                          {!location?.state?.state?.data?.data
                            ?.relevantWorkExperience ? (
                            <span className="hire-optional">
                              <i> Optional</i>
                            </span>
                          ) : (
                            <span>
                              <BsDot color="red" size={25} />
                              <i className="hire-impotant">Important</i>
                            </span>
                          )}
                        </p>
                        <input
                          type="number"
                          className="AddJobinput"
                          value={inputs?.relevantWorkExperience}
                          onChange={(e) =>
                            handleOnChange(
                              "relevantWorkExperience",
                              e.target.value
                            )
                          }
                          placeholder="Relevant Work Experience"
                          required={
                            location?.state?.state?.data?.data
                              ?.relevantWorkExperience
                          }
                        />
                      </div>
                    )}
                    <div className="notice__period">
                      <p>
                        Resume{" "}
                        {!location?.state?.state?.data?.data?.resume ? (
                          <span className="hire-optional">
                            <i> Optional</i>
                          </span>
                        ) : (
                          <span>
                            <BsDot color="red" size={25} />{" "}
                            <i className="hire-impotant">Important</i>
                          </span>
                        )}
                      </p>
                      <input
                        type="text"
                        className="AddJobinput"
                        value={inputs.resume}
                        onChange={(e) =>
                          handleOnChange("resume", e.target.value)
                        }
                        placeholder="Resume"
                        required
                        disabled
                      />
                    </div>
                  </div>

                  <div className="notice__period">
                    <p>Verification Indentificaton</p>
                    <input
                      type="text"
                      className="AddJobinput"
                      value={inputs.verificationID}
                      onChange={(e) =>
                        handleOnChange("verificationID", e.target.value)
                      }
                      placeholder="Verification Indentificaton with date of birth"
                      required
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>

            {location?.state?.state?.data?.data?.educationDetails && (
              <div className="Hire-first-second-main-container Hire-main-apply">
                <div id="Total__Experience">
                  {date1?.map((item, index) => (
                    <div className="Information__basic" key={index}>
                      <div className="Recruiting__infoBox">
                        <div className="Recruiting-tab-add">
                          <p>
                            Education{" "}
                            {!location?.state?.state?.data?.data
                              ?.educationDetails ? (
                              <span className="hire-optional">
                                <i> Optional</i>
                              </span>
                            ) : (
                              <span>
                                <BsDot color="red" size={25} />{" "}
                                <i className="hire-impotant">Important</i>
                              </span>
                            )}
                          </p>
                          <div>
                            {index === 0 ? (
                              <Button onClick={handleAddField1}>
                                <IoMdAddCircle
                                  size={25}
                                  className="Recruiting-tab-add-btn"
                                />
                              </Button>
                            ) : (
                              <Button onClick={handleRemoveField1}>
                                <AiFillMinusCircle
                                  size={25}
                                  className="Recruiting-tab-add-btn1"
                                />
                              </Button>
                            )}
                          </div>
                        </div>
                        <hr />
                        <div className="Recruiting__info">
                          <div className="New__applicant__column">
                            <p>
                              {" "}
                              School{" "}
                              {!location?.state?.state?.data?.data
                                ?.educationDetails ? (
                                <span className="hire-optional">
                                  <i> Optional</i>
                                </span>
                              ) : (
                                <span>
                                  <BsDot color="red" size={25} />{" "}
                                  <i className="hire-impotant">Important</i>
                                </span>
                              )}
                            </p>
                            <input
                              type="text"
                              className="AddJobinput"
                              // @ts-ignore
                              value={item.school}
                              onChange={(e) =>
                                handleData1("school", e.target.value, item.id)
                              }
                              placeholder="School"
                              required={
                                location?.state?.state?.data?.data
                                  ?.educationDetails
                              }
                            />
                          </div>

                          <div className="New__applicant__column">
                            <p>
                              Course{" "}
                              {!location?.state?.state?.data?.data
                                ?.educationDetails ? (
                                <span className="hire-optional">
                                  <i> Optional</i>
                                </span>
                              ) : (
                                <span>
                                  <BsDot color="red" size={25} />{" "}
                                  <i className="hire-impotant">Important</i>
                                </span>
                              )}
                            </p>
                            <input
                              type="text"
                              className="AddJobinput"
                              // @ts-ignore
                              value={item.course}
                              onChange={(e) =>
                                handleData1("course", e.target.value, item.id)
                              }
                              placeholder="course"
                              required={
                                location?.state?.state?.data?.data
                                  ?.educationDetails
                              }
                            />
                          </div>

                          <div className="New__applicant__column">
                            <p>
                              {" "}
                              Start Date{" "}
                              {!location?.state?.state?.data?.data
                                ?.educationDetails ? (
                                <span className="hire-optional">
                                  <i> Optional</i>
                                </span>
                              ) : (
                                <span>
                                  <BsDot color="red" size={25} />{" "}
                                  <i className="hire-impotant">Important</i>
                                </span>
                              )}
                            </p>
                            <input
                              type="date"
                              className="AddJobinput"
                              // @ts-ignore
                              value={moment(new Date(item.startDate)).format(
                                "YYYY-MM-DD"
                              )}
                              onChange={(e) =>
                                handleData1(
                                  "startDate",
                                  e.target.value,
                                  item.id
                                )
                              }
                              placeholder="Start Date1"
                              required={
                                location?.state?.state?.data?.data
                                  ?.educationDetails
                              }
                            />
                          </div>

                          <div className="New__applicant__column">
                            <p>
                              End Date{" "}
                              {!location?.state?.state?.data?.data
                                ?.educationDetails ? (
                                <span className="hire-optional">
                                  <i> Optional</i>
                                </span>
                              ) : (
                                <span>
                                  <BsDot color="red" size={25} />{" "}
                                  <i className="hire-impotant">Important</i>
                                </span>
                              )}
                            </p>

                            <input
                              type="date"
                              className="AddJobinput"
                              // @ts-ignore
                              value={moment(new Date(item?.endDate)).format(
                                "YYYY-MM-DD"
                              )}
                              onChange={(e) =>
                                handleData1("endDate", e.target.value, item.id)
                              }
                              placeholder="End Date"
                              required={
                                location?.state?.state?.data?.data
                                  ?.educationDetails
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {location?.state?.state?.data?.data?.totalWorkExperience && (
              <div className="Hire-first-second-main-container Hire-main-apply">
                <div id="Total__Experience">
                  {date2?.map((item, index) => (
                    <div className="Information__basic" key={index}>
                      <div className="Recruiting__infoBox">
                        <div className="Recruiting-tab-add">
                          <p>
                            Work Experience{" "}
                            {!location?.state?.state?.data?.data
                              ?.totalWorkExperience ? (
                              <span className="hire-optional">
                                <i> Optional</i>
                              </span>
                            ) : (
                              <span>
                                <BsDot color="red" size={25} />
                                <i className="hire-impotant">Important</i>
                              </span>
                            )}
                          </p>
                          <div>
                            {index === 0 ? (
                              <Button onClick={handleAddField2}>
                                <IoMdAddCircle
                                  size={25}
                                  className="Recruiting-tab-add-btn"
                                />
                              </Button>
                            ) : (
                              <Button onClick={handleRemoveField2}>
                                <AiFillMinusCircle
                                  size={25}
                                  className="Recruiting-tab-add-btn1"
                                />
                              </Button>
                            )}
                          </div>
                        </div>
                        <hr />
                        <div className="Recruiting__info">
                          <div className="New__applicant__column">
                            <p>
                              Company{" "}
                              {!location?.state?.state?.data?.data
                                ?.totalWorkExperience ? (
                                <span className="hire-optional">
                                  <i> Optional</i>
                                </span>
                              ) : (
                                <span>
                                  <BsDot color="red" size={25} />
                                  <i className="hire-impotant">Important</i>
                                </span>
                              )}
                            </p>
                            <input
                              type="text"
                              className="AddJobinput"
                              value={item?.company}
                              onChange={(e) =>
                                handleData2("company", e.target.value, item?.id)
                              }
                              placeholder="Company"
                              required={
                                location?.state?.state?.data?.data
                                  ?.totalWorkExperience
                              }
                            />
                          </div>
                          <div className="New__applicant__column">
                            <p>
                              Role{" "}
                              {!location?.state?.state?.data?.data
                                ?.totalWorkExperience ? (
                                <span className="hire-optional">
                                  <i> Optional</i>
                                </span>
                              ) : (
                                <span>
                                  <BsDot color="red" size={25} />
                                  <i className="hire-impotant">Important</i>
                                </span>
                              )}
                            </p>
                            <input
                              type="text"
                              className="AddJobinput"
                              value={item?.role}
                              onChange={(e) =>
                                handleData2("role", e.target.value, item?.id)
                              }
                              placeholder="role"
                              required={
                                location?.state?.state?.data?.data
                                  ?.totalWorkExperience
                              }
                            />
                          </div>

                          <div className="New__applicant__column">
                            <p>
                              Start Date{" "}
                              {!location?.state?.state?.data?.data
                                ?.totalWorkExperience ? (
                                <span className="hire-optional">
                                  <i> Optional</i>
                                </span>
                              ) : (
                                <span>
                                  <BsDot color="red" size={25} />
                                  <i className="hire-impotant">Important</i>
                                </span>
                              )}{" "}
                            </p>
                            <input
                              type="date"
                              className="AddJobinput"
                              value={moment(new Date(item?.startDate)).format(
                                "YYYY-MM-DD"
                              )}
                              onChange={(e) =>
                                handleData2(
                                  "startDate",
                                  e.target.value,
                                  item?.id
                                )
                              }
                              placeholder="Start Date"
                              required={
                                location?.state?.state?.data?.data
                                  ?.totalWorkExperience
                              }
                            />
                          </div>
                          <div className="New__applicant__column">
                            <p>
                              End Date{" "}
                              {!location?.state?.state?.data?.data
                                ?.totalWorkExperience ? (
                                <span className="hire-optional">
                                  <i> Optional</i>
                                </span>
                              ) : (
                                <span>
                                  <BsDot color="red" size={25} />
                                  <i className="hire-impotant">Important</i>
                                </span>
                              )}
                            </p>

                            <input
                              type="date"
                              className="AddJobinput"
                              value={moment(new Date(item?.endDate)).format(
                                "YYYY-MM-DD"
                              )}
                              onChange={(e) =>
                                handleData2("endDate", e.target.value, item.id)
                              }
                              placeholder="End Date"
                              required={
                                location?.state?.state?.data?.data
                                  ?.totalWorkExperience
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="Hire-first-second-main-container Hire-main-apply">
              <div className="hire-apply-btn-container">
                {isSuccessapp ? (
                  ""
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    className="summary-bottom-btn">
                    {isLoadingapp ? <Spinners size={"md"} /> : "Apply"}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
};

export default HireForm;
