import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import LoaderPlus from "../components/LoaderPlus";
import { baseUrl } from "../shared/baseUrl";
import axios from "axios";
import { Accordion } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import swal from "sweetalert";

const CurrentOpenings = ({ history }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [activeKey, setActiveKey] = useState(null);
  const handleAccordionClick = (eventKey) => {
    if (activeKey === eventKey) {
      setActiveKey(null);
    } else {
      setActiveKey(eventKey);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(baseUrl + "/api/v1/job");
        setData(data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // Extract and display the error message
        const errorMessage =
          error.response && error.response.data
            ? error.response.data.errors[0].message
            : error.message || "An error occurred. Please try again.";

        swal({
          title: "Error",
          text: errorMessage,
          icon: "error",
        });
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <Navbar />
      <div className="current-page-img">
        <div></div>
      </div>
      <main>
        <div>
          <h1 className="current-header" data-aos="fade-down">
            Available <span id="openings-colour"> Openings</span>
          </h1>
        </div>
        <div>
          <br />
        </div>
        {loading && <LoaderPlus />}

        {data?.map((data, index) => (
          <Accordion activeKey={activeKey} onSelect={handleAccordionClick}>
            <div key={index}>
              <Accordion.Item eventKey={data?.id}>
                <div id="accordion-body-form">
                  <Accordion.Header>{data?.jobCode} - {data?.jobTitle}</Accordion.Header>
                  <Accordion.Body>
                    <div id="panel-content-h5" className="panel-content">
                      {data?.summary && (
                        <>
                          <h5>Job Overview</h5>
                          <p>{data?.summary}</p>
                        </>
                      )}

                      {data?.jobRole && (
                        <>
                          <h5>Responsibilities</h5>
                          <p>{data?.jobRole}</p>
                        </>
                      )}

                      {data?.qualifications && (
                        <>
                          <h5>Qualifications</h5>
                          <p>{data?.qualifications}</p>
                        </>
                      )}

                      {data?.mustHaveSkills?.length > 0 && (
                        <>
                          <h5>Must-Have Skills</h5>
                          <ul>
                            {data?.mustHaveSkills.map((skill, i) => (
                              <li key={i}>{skill}</li>
                            ))}
                          </ul>
                        </>
                      )}

                      {data?.goodHaveSkills?.length > 0 && (
                        <>
                          <h5>Good-to-Have Skills</h5>
                          <ul>
                            {data?.goodHaveSkills.map((skill, i) => (
                              <li key={i}>{skill}</li>
                            ))}
                          </ul>
                        </>
                      )}

                      {data?.benefits && (
                        <>
                          <h5>Benefits</h5>
                          <p>{data?.benefits}</p>
                        </>
                      )}

                      {data?.jobLocation && (
                        <>
                          <h5>Location</h5>
                          <p>{data?.jobLocation}</p>
                        </>
                      )}

                      {data?.employmentType && (
                        <>
                          <h5>Employment Type</h5>
                          <p>{data?.employmentType}</p>
                        </>
                      )}

                      {data?.experienceLevel && (
                        <>
                          <h5>Experience Level</h5>
                          <p>{data?.experienceLevel}</p>
                        </>
                      )}

                      {data?.education && (
                        <>
                          <h5>Education Requirement</h5>
                          <p>{data?.education}</p>
                        </>
                      )}

                      {data?.remote !== undefined && (
                        <>
                          <h5>Remote Work</h5>
                          <p>{data?.remote ? "Yes" : "No"}</p>
                        </>
                      )}

                      {data?.openingDate && (
                        <>
                          <h5>Opening Date</h5>
                          <p>
                            {new Date(data?.openingDate).toLocaleDateString()}
                          </p>
                        </>
                      )}

                      {data?.expiryDate && (
                        <>
                          <h5>Expiry Date</h5>
                          <p>
                            {new Date(data?.expiryDate).toLocaleDateString()}
                          </p>
                        </>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}>
                      <div>
                        <NavLink to={`/hire/${data?.id}/`}>
                          <button className="me-2  ccsnl-btn">Apply Now</button>
                        </NavLink>
                      </div>
                    </div>
                  </Accordion.Body>
                </div>
              </Accordion.Item>
            </div>
          </Accordion>
        ))}
      </main>
      <Footer />
    </div>
  );
};

export default CurrentOpenings;
