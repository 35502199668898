import BarLoader from "react-spinners/BarLoader";

const TableLoader = ({ isLoading }) => {
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    width: "99.8%",
    borderRadius: "50px",
  };

  return (
    <div>
      <BarLoader
        cssOverride={override}
        color={"rgb(0, 91, 144)"}
        loading={isLoading}
      />
    </div>
  );
};

export default TableLoader;
