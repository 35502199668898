import React from "react";
import { Spinner } from "react-bootstrap";

const TableProgressBar = () => (
  <div id="container-progressbar">
    <div id="bar"></div>
  </div>
);

const Spinners = ({ size }) => (
  <Spinner
    as="span"
    animation="border"
    size={size}
    role="status"
    aria-hidden="true"
  />
);

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export { TableProgressBar, Spinners, months };
