export const statusOption = [
  "Send for screening",
  "Send welcome email",
  "Edit tagged recruiter",
  "Schedule",
  "Reject",
  "Dropout",
  "On Hold",
  "Edit",
  "Offered",
  "Hired",
];

export const jobFuntion = [
  "Loan Recovery",
  "Customer Care",
  "Accounting",
  "Administrative",
  "Arts and Design",
  "Business Development",
  "Community and Social Services",
  "Consulting",
  "Education",
  "Engineering",
  "Entrepreneurship",
  "Finance",
  "Healthcare Services",
  "Human Resources",
  "Information Technology",
  "Legal",
  "Marketing",
  "Media and Communication",
  "Military and Protective Services",
  "Operations",
  "Product Management",
  "Program and Project Management",
  "Purchasing",
  "Quality Assurance",
  "Real Estate",
  "Research",
  "Sales",
  "Support",
];

export const JobProfile = [
  "Loan Recovery",
  "Customer Care",
  "Accounting",
  "Administrative",
  "Arts and Design",
  "Business Development",
  "Community and Social Services",
  "Consulting",
  "Education",
  "Engineering",
  "Entrepreneurship",
  "Finance",
  "Healthcare Services",
  "Human Resources",
  "Information Technology",
  "Legal",
  "Marketing",
  "Media and Communication",
  "Military and Protective Services",
  "Operations",
  "Product Management",
  "Program and Project Management",
  "Purchasing",
  "Quality Assurance",
  "Real Estate",
  "Research",
  "Sales",
  "Support",
];
export const team = [
  "Loan Recovery",
  "Customer Care",
  "Accounting",
  "Administrative",
  "Arts and Design",
  "Business Development",
  "Community and Social Services",
  "Consulting",
  "Education",
  "Engineering",
  "Entrepreneurship",
  "Finance",
  "Healthcare Services",
  "Human Resources",
  "Information Technology",
  "Legal",
  "Marketing",
  "Media and Communication",
  "Military and Protective Services",
  "Operations",
  "Product Management",
  "Program and Project Management",
  "Purchasing",
  "Quality Assurance",
  "Real Estate",
  "Research",
  "Sales",
  "Support",
];
export const employmentType = [
  "Intern",
  "Contract",
  "Full Time",
  "Part Time",
  "Probation",
  "Permatent",
  "Commission",
  "Freelancer",
  "Agency Resource",
  "Temporary",
  "Consultant",
  "Apprentice",
];

export const sourceType = [
  "Agency",
  "Enternal Recruiter",
  "Internal Recruiter",
  "Direct",
  "Social",
];
export const workplaceType = ["Remote", "Onsite", "Hybid"];

export const Salutation = ["Mr", "Mrs", "Miss", "Sir", "Dr"];
export const Gender = ["Male", "Female"];
export const experienceLevel = [
  "Entry - level(0 – 2 years of experience)",
  "Mid - level(3 - 5 years of experience)",
  "Senior - level(6 – 9 years of experience)",
  "Management - level(10+ years of experience)",
];
